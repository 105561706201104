import { type Reason } from '../../../shared/enums/contactForm';
import {
    type FormSchemaField,
    type FormValues,
    type MailContent,
} from '../../../shared/types/contactForm';
import { fileToBase64 } from '../../helper/fileToBase64';
import { formSchema } from './config';
import { FilesizeExceedError } from './error/FilesizeExceedError';

const MAX_FILE_SIZE_BYTES = 20_971_520; // 20 MB

const getMailContent = async (
    selectedReason: Reason,
    values: FormValues,
    formatMessage: (message: { defaultMessage: string }) => string,
): Promise<MailContent> => {
    let contactInformation = '';
    let bodyMessage = '';
    formSchema[selectedReason].forEach((element: FormSchemaField): void => {
        const { name, message } = element;
        if (
            ['firstName', 'lastName', 'email', 'street', 'zipCode', 'houseNumber', 'city'].includes(
                name,
            ) &&
            name !== 'file'
        ) {
            contactInformation += `${formatMessage(message)}: ${values[name] || ''}
            \n`;
        } else if (name !== 'file') {
            bodyMessage += `${formatMessage(message)}: ${values[name] || ''}
            \n`;
        }
    });
    const fileBase64 = await Promise.resolve(values.file ? fileToBase64(values.file) : values.file);

    if (fileBase64 && fileBase64.length > MAX_FILE_SIZE_BYTES) {
        throw new FilesizeExceedError();
    }

    return {
        from: values.email as string,
        subject: selectedReason,
        body: bodyMessage,
        contact: contactInformation,
        experience: values.experience,
        file: {
            name: values.file?.name,
            contentType: values.file?.type,
            data: fileBase64,
        },
    };
};

export { getMailContent };
