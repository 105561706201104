import { defineMessages } from 'react-intl';

const messages = defineMessages({
    reasonExperience: {
        defaultMessage: 'Ich habe eine Frage zu einem Erlebnis',
    },
    uploadText: {
        defaultMessage: 'Datei auswählen',
    },
    fileTooLarge: {
        defaultMessage: 'Die angehängte Datei ist zu groß',
    },
    fileFormatUnsupported: {
        defaultMessage: 'Dieses Dateiformat wird nicht unterstützt',
    },
    reasonCoupon: {
        defaultMessage: 'Ich habe eine Frage zu einem Gutschein',
    },
    reasonShipment: {
        defaultMessage: 'Ich möchte eine Sendungsauskunft zu meiner Bestellung',
    },
    reasonRevocation: {
        defaultMessage: 'Ich möchte meine Bestellung widerrufen',
    },
    reasonOther: {
        defaultMessage: 'Ich habe ein anderes Anliegen',
    },
    reasonDateChange: {
        defaultMessage: 'Ich möchte einen Termin vereinbaren/verschieben/absagen',
    },
    reasonGroup: {
        defaultMessage: 'Ich möchte eine Buchung für eine Gruppe vornehmen',
    },
    reasonAdvertising: {
        defaultMessage: 'Ich möchte keine Werbung erhalten',
    },
    reasonFeedback: {
        defaultMessage: 'Ich möchte Feedback zu einem Erlebnis abgeben',
    },
    firstName: {
        defaultMessage: 'Vorname',
    },
    lastName: {
        defaultMessage: 'Nachname',
    },
    email: {
        defaultMessage: 'E-Mail',
    },
    code: {
        defaultMessage: 'Gutscheincode',
    },
    experience: {
        defaultMessage: 'Erlebnis',
    },
    location: {
        defaultMessage: 'Erlebnisort',
    },
    question: {
        defaultMessage: 'Welche Frage können wir dir beantworten?',
    },
    orderedItems: {
        defaultMessage: 'Bestellte Artikel',
    },
    remarks: {
        defaultMessage: 'Anmerkungen',
    },
    revocation: {
        defaultMessage:
            'Hiermit widerrufe(n) ich/wir den von mir/uns abgeschlossenen Vertrag über den Kauf der folgenden Waren / die Erbringung der folgenden Dienstleistungen',
    },
    orderedOn: {
        defaultMessage: 'Bestellt am',
    },
    receivedOn: {
        defaultMessage: 'Erhalten am',
    },
    orderNumber: {
        defaultMessage: 'Bestellnummer/ Auftragsnummer',
    },
    date: {
        defaultMessage: 'Datum',
    },
    street: {
        defaultMessage: 'Straße',
    },
    houseNumber: {
        defaultMessage: 'Hausnummer',
    },
    city: {
        defaultMessage: 'Ort',
    },
    zipCode: {
        defaultMessage: 'PLZ',
    },
    feedback: {
        defaultMessage: 'Dein Feedback',
    },
    newspaper: {
        defaultMessage: 'Redaktion/Zeitschrift',
    },
    phone: {
        defaultMessage: 'Telefonnummer',
    },
    myMessage: {
        defaultMessage: 'Meine Nachricht',
    },
    send: {
        defaultMessage: 'Absenden',
    },
    mandatory: {
        defaultMessage: 'Mit * gekennzeichnete Felder sind Pflichtfelder',
    },
    dataTransfer: {
        defaultMessage:
            'Die Übertragung deiner Daten erfolgt verschlüsselt. Wir verarbeiten deine personenbezogenen Daten aufgrund berechtigen Interesses zur Beantwortung deiner Anfrage und löschen die Daten, wenn deine Anfrage erkennbar endgültig erledigt ist oder du wirksam die weitere Verarbeitung deiner Daten uns gegenüber widerrufst. Im Übrigen gelten unsere',
    },
    private: {
        defaultMessage: 'Privatkunde',
    },
    corporate: {
        defaultMessage: 'Firmenkunde',
    },
    press: {
        defaultMessage: 'Pressevertreter',
    },
    provider: {
        defaultMessage: 'Erlebnisanbieter',
    },
    invalidEmail: {
        defaultMessage: 'Ungültige E-Mail Adresse',
    },
    fieldRequired: {
        defaultMessage: 'Dieses Feld ist ein Pflichtfeld',
    },
    fileUpload: {
        defaultMessage: 'Möchtest Du eine Datei anfügen? (max 20 MB)',
    },
    successMessageTitle: {
        defaultMessage: 'Vielen Dank für deine Anfrage',
    },
    successMessageBody: {
        defaultMessage: 'Wir werden diese schnellstmöglich bearbeiten.',
    },
    errorMessageTitle: {
        defaultMessage: 'Es ist ein Fehler aufgetreten',
    },
    errorMessageBody: {
        defaultMessage:
            'Ein technischer Fehler ist aufgetreten, bitte versuchen Sie es später erneut',
    },
    errorMessageSize: {
        defaultMessage:
            'Die angehängte Datei ist zu groß. Bitte wählen Sie eine Datei mit maximal 20 MB aus.',
    },
    a11yRemoveFileText: {
        defaultMessage: 'Datei löschen',
    },
    link: {
        defaultMessage: 'Link zum Erlebnis',
    },
    dates: {
        defaultMessage: 'Wunschtermin(e)',
    },
    time: {
        defaultMessage: 'Wunschuhrzeit',
    },
    amount: {
        defaultMessage: 'Anzahl Teilnehmer (min. 6 Personen)',
    },
});

export { messages };
