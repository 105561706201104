import { FormAction } from '../../shared/enums/contactForm';

type State = {
    success: boolean;
    buttonState: string;
    error?: boolean;
    errorText?: string;
    errorContent?: string;
    confirmationContent?: string;
};

type Action = {
    type: FormAction;
    payload?: string;
};

export const contactFormReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case FormAction.Loading:
            return {
                ...state,
                success: false,
                error: false,
                buttonState: 'loading',
                errorContent: '',
                confirmationContent: '',
            };
        case FormAction.Success:
            return {
                ...state,
                success: true,
                error: false,
                buttonState: 'enabled',
                errorContent: '',
                confirmationContent: action.payload,
            };
        case FormAction.Error:
            return {
                ...state,
                success: false,
                error: true,
                buttonState: 'enabled',
                errorContent: action.payload,
                confirmationContent: '',
            };
        default:
            return state;
    }
};
