import { type ChangeEvent, Fragment, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import { Formik, type FormikProps } from 'formik';
import * as Yup from 'yup';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import {
    ButtonColor,
    ButtonLoader,
    ButtonType,
    Input,
    InputFile,
    Link,
    LinkVariant,
    Textarea,
    ValidationStates,
} from '@jsmdg/yoshi';
import { FieldName, Reason } from '../../../../../shared/enums/contactForm';
import {
    type BasicField,
    type FormSchema,
    type FormValues,
} from '../../../../../shared/types/contactForm';
import { type FragmentContext } from '../../../../../shared/types/fragmentContext';
import { formSchema, messages } from '../../config';
import { FormDescription } from './FormDescription';

const FILE_SIZE = 20 * 1_024 * 1_024; // 20 MB

const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/png',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/pdf',
];

type CustomerFormProps = {
    readonly reason: Reason;
    readonly submitButtonState: string;
    readonly onFormSubmit: (formValues: FormValues) => Promise<void>;
};

const getValidationState = (isTouched: boolean, error: string): ValidationStates | undefined =>
    isTouched && error ? ValidationStates.Error : undefined;

const getHelperText = (isTouched: boolean, error: string): string | undefined =>
    isTouched && error ? error : undefined;

const getClassName = (fieldName: string): string => {
    switch (fieldName) {
        case FieldName.Street:
            return `g-col-xs-12 g-col-sm-8`;
        case FieldName.HouseNumber:
            return `g-col-xs-12 g-col-sm-4 pl-sm-1x`;
        case FieldName.ZipCode:
            return `g-col-xs-12 g-col-sm-4`;
        case FieldName.City:
            return `g-col-xs-12 g-col-sm-8 pl-sm-1x`;
        default:
            return `g-col-12`;
    }
};

const CustomerForm = ({
    onFormSubmit,
    reason,
    submitButtonState,
}: CustomerFormProps): JSX.Element => {
    const intl = useIntl();
    const { tenantConfig } = useFragmentContext<FragmentContext>();
    const makeValidationSchema = (): FormSchema => {
        const schema: FormSchema = {};
        formSchema[reason].map(field => {
            if (field.name === FieldName.Email && field.required) {
                schema[field.name] = Yup.string()
                    .email(intl.formatMessage(messages.invalidEmail))
                    .required(intl.formatMessage(messages.fieldRequired));
            }

            if (field.name === FieldName.Email && !field.required) {
                schema[field.name] = Yup.string().email(intl.formatMessage(messages.invalidEmail));
            }

            if (field.name !== FieldName.Email && field.required) {
                schema[field.name] = Yup.string().required(
                    intl.formatMessage(messages.fieldRequired),
                );
            }

            if (field.name === FieldName.File && !field.required) {
                schema[field.name] = Yup.mixed()
                    .nullable()
                    .notRequired()
                    .test(
                        'fileFormat',
                        intl.formatMessage(messages.fileFormatUnsupported),
                        (file: File) => !file || SUPPORTED_FORMATS.includes(file.type),
                    )
                    .test(
                        'fileSize',
                        intl.formatMessage(messages.fileTooLarge),
                        (file: File) => !file || file.size <= FILE_SIZE,
                    );
            }

            return null;
        });

        return schema;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const validationSchema = useMemo(() => Yup.object().shape(makeValidationSchema()), [reason]);

    const handleInputControlChange = async (
        formik: FormikProps<FormValues>,
        event: ChangeEvent<HTMLInputElement>,
    ): Promise<void> => {
        const { files, type } = event.target;
        if (type === 'file' && files) {
            await formik.setFieldTouched('file', true);
            await formik.setFieldValue('file', files[0]);
        } else {
            formik.handleChange(event);
        }
    };

    const handleClearFile = async (formik: FormikProps<FormValues>): Promise<void> => {
        await formik.setFieldTouched('file', false);
        await formik.setFieldValue('file', '');
    };

    const getForm = (
        formik: FormikProps<FormValues>,
        element: BasicField,
        validationState?: ValidationStates,
        helperText?: string,
    ): JSX.Element => {
        if (element.textarea) {
            return (
                <div className="g-col-12 px-0">
                    <Textarea
                        key={`field-${element.name}`}
                        id={element.name}
                        name={element.name}
                        className={classnames(getClassName(element.name), 'mb-sm-0-5x p-0 my-2x')}
                        label={element.message.defaultMessage}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        hasRequiredMarker={element.required}
                        validationState={validationState}
                        helperText={helperText}
                        showHelperText
                        value={formik.values[element.name] as string}
                    />
                </div>
            );
        }

        if (element.type === 'file') {
            return (
                <div className="g-col-12">
                    <InputFile
                        key={`field-${element.name}`}
                        name={element.name}
                        id={element.name}
                        labelClasses="p-0 mt-4x"
                        label={element.message.defaultMessage}
                        onChange={async e => handleInputControlChange(formik, e)}
                        onBlur={formik.handleBlur}
                        hasRequiredMarker={element.required}
                        validationState={validationState}
                        helperText={helperText}
                        showHelperText
                        placeholder={intl.formatMessage(messages.uploadText)}
                        value={formik.values[element.name] as File}
                        onFileClear={() => handleClearFile(formik) as unknown as () => void}
                        accept={SUPPORTED_FORMATS.join(', ')}
                        a11yRemoveFileText={intl.formatMessage(messages.a11yRemoveFileText)}
                    />
                </div>
            );
        }

        return (
            <div className="g-col-12 p-0 mt-2x">
                <Input
                    key={`field-${element.name}`}
                    className="w-100"
                    inputClasses="w-100"
                    name={element.name}
                    id={element.name}
                    placeholder={element.message.defaultMessage}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    hasRequiredMarker={element.required}
                    validationState={validationState}
                    helperText={helperText}
                    showHelperText
                    value={formik.values[element.name] as string}
                />
            </div>
        );
    };

    return (
        <Formik
            onSubmit={onFormSubmit}
            validationSchema={validationSchema}
            initialValues={
                // eslint-disable-next-line unicorn/prefer-object-from-entries
                formSchema[reason].reduce(
                    (values: FormSchema, field: BasicField): FormSchema => ({
                        ...values,
                        [field.name]: '',
                    }),
                    {},
                ) as FormValues
            }
        >
            {formik => (
                <form onSubmit={formik.handleSubmit}>
                    <FormDescription selectedReason={reason} />
                    <div className="grid mx-0">
                        {formSchema[reason].map(element => {
                            const validationState = getValidationState(
                                !!formik.touched[element.name],
                                formik.errors[element.name] as string,
                            );
                            const helperText = getHelperText(
                                !!formik.touched[element.name],
                                formik.errors[element.name] as string,
                            );
                            return (
                                <Fragment key={`field-${element.name}`}>
                                    {getForm(formik, element, validationState, helperText)}
                                </Fragment>
                            );
                        })}
                    </div>
                    {reason !== Reason.DateChange && (
                        <div className="grid">
                            <div className="g-col-12">
                                <p className="fst-italic mb-3x">
                                    <FormattedMessage defaultMessage="Mit * gekennzeichnete Felder sind Pflichtfelder" />
                                </p>
                            </div>
                            <div className="g-col-12 mb-3x ml-sm-auto">
                                <ButtonLoader
                                    type={ButtonType.Submit}
                                    className="w-100"
                                    color={ButtonColor.Complementary}
                                    isLoading={submitButtonState === 'loading'}
                                    loadingText={intl.formatMessage(messages.send)}
                                >
                                    <span>
                                        <FormattedMessage defaultMessage="Absenden" />
                                    </span>
                                </ButtonLoader>
                            </div>
                            <div className="g-col-12">
                                <p className="fst-italic mb-3x">
                                    <FormattedMessage defaultMessage="Wir verarbeiten deine personenbezogenen Daten aufgrund berechtigen Interesses zur Beantwortung deiner Anfrage und löschen die Daten, wenn deine Anfrage erkennbar endgültig erledigt ist oder du wirksam die weitere Verarbeitung deiner Daten uns gegenüber widerrufst. Im Übrigen gelten unsere " />
                                    <Link
                                        variant={LinkVariant.Brand}
                                        href={tenantConfig.urls.privacyPolicy}
                                        internal
                                    >
                                        <FormattedMessage defaultMessage="Datenschutzbestimmungen" />
                                    </Link>
                                    <span>.</span>
                                </p>
                            </div>
                        </div>
                    )}
                </form>
            )}
        </Formik>
    );
};

export { CustomerForm };
