import { FormattedMessage } from 'react-intl';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { Button, ButtonColor, RenderType } from '@jsmdg/yoshi';
import { type FragmentContext } from '../../../../../shared/types/fragmentContext';

const CorporateForm = (): JSX.Element => {
    const { tenantConfig }: FragmentContext = useFragmentContext();

    return (
        <div>
            <p className="mb-2x">
                <FormattedMessage
                    defaultMessage="{description}"
                    values={{ description: tenantConfig.corporateFormDescription }}
                />
            </p>
            <Button
                as={RenderType.Link}
                color={ButtonColor.Complementary}
                href={tenantConfig.urls.b2bContact}
                target="_blank"
            >
                <FormattedMessage defaultMessage="Kontakt Firmenkunde" />
            </Button>
        </div>
    );
};

export { CorporateForm };
