import { useReducer } from 'react';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { Select } from '@jsmdg/yoshi';
import { Customer, FormAction, Reason } from '../../../shared/enums/contactForm';
import { type FormValues } from '../../../shared/types/contactForm';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import { contactFormReducer } from '../../reducers/contactFormReducer';
import { ErrorMessage } from '../ErrorMessage';
import { SuccessMessage } from '../SuccessMessage';
import { messages } from './config';
import { FilesizeExceedError } from './error/FilesizeExceedError';
import { CorporateForm } from './Forms/Corporate';
import { CustomerForm } from './Forms/Customer';
import { ProviderForm } from './Forms/Provider';
import { getMailContent } from './getMailContent';

const ContactForm = (): JSX.Element => {
    const intl = useIntl();
    const { submitContactForm } = useFragmentContext<FragmentContext>();

    const customerTypes = [
        {
            label: intl.formatMessage(messages.private),
            value: Customer.Private,
        },
        {
            label: intl.formatMessage(messages.corporate),
            value: Customer.Corporate,
        },
        {
            label: intl.formatMessage(messages.press),
            value: Customer.Press,
        },
        {
            label: intl.formatMessage(messages.provider),
            value: Customer.Provider,
        },
    ];

    const reasons = [
        {
            label: intl.formatMessage(messages.reasonExperience),
            value: Reason.Experience,
        },
        {
            label: intl.formatMessage(messages.reasonCoupon),
            value: Reason.Coupon,
        },
        {
            label: intl.formatMessage(messages.reasonShipment),
            value: Reason.Shipment,
        },
        {
            label: intl.formatMessage(messages.reasonRevocation),
            value: Reason.Revocation,
        },
        {
            label: intl.formatMessage(messages.reasonOther),
            value: Reason.Other,
        },
        {
            label: intl.formatMessage(messages.reasonDateChange),
            value: Reason.DateChange,
        },
        {
            label: intl.formatMessage(messages.reasonGroup),
            value: Reason.Group,
        },
        {
            label: intl.formatMessage(messages.reasonAdvertising),
            value: Reason.Advertising,
        },
        {
            label: intl.formatMessage(messages.reasonFeedback),
            value: Reason.Feedback,
        },
    ];

    const formik = useFormik({
        initialValues: {
            selectedCustomerType: customerTypes[0].value,
            selectedReason: reasons[0].value,
        },
        onSubmit: () => undefined,
    });

    const [{ buttonState, confirmationContent, error, errorContent, success }, dispatch] =
        useReducer(contactFormReducer, {
            success: false,
            buttonState: 'enabled',
            errorText: '',
            confirmationContent: '',
        });

    const {
        handleChange,
        values: { selectedCustomerType, selectedReason },
    } = formik;

    const onSubmit = async (formValues: FormValues): Promise<void> => {
        try {
            const reason = selectedCustomerType === Customer.Press ? Reason.Press : selectedReason;
            dispatch({ type: FormAction.Loading });
            // eslint-disable-next-line @typescript-eslint/unbound-method
            const emailContent = await getMailContent(reason, formValues, intl.formatMessage);
            await submitContactForm(emailContent);

            dispatch({
                type: FormAction.Success,
                payload: `<h3>${intl.formatMessage(
                    messages.successMessageTitle,
                )}</h3><p>${intl.formatMessage(messages.successMessageBody)}</p>`,
            });
            document
                .querySelector('[data-id=product-listing-fragment]')
                ?.scrollIntoView({ behavior: 'smooth' });
        } catch (contactFormError) {
            if (
                contactFormError instanceof Error &&
                contactFormError.name === FilesizeExceedError.name
            ) {
                dispatch({
                    type: FormAction.Error,
                    payload: `<h3>${intl.formatMessage(
                        messages.errorMessageTitle,
                    )}</h3><p>${intl.formatMessage(messages.errorMessageSize)}</p>`,
                });
                return;
            }

            dispatch({
                type: FormAction.Error,
                payload: `<h3>${intl.formatMessage(
                    messages.errorMessageTitle,
                )}</h3><p>${intl.formatMessage(messages.errorMessageBody)}</p>`,
            });
        }
    };

    const renderCustomerForm = (): JSX.Element => {
        if (success) {
            return <SuccessMessage content={confirmationContent || ''} />;
        }

        if (error) {
            return <ErrorMessage content={errorContent || ''} />;
        }

        return (
            <div className="w-100">
                <Select
                    placeholder="Bitte auswählen"
                    options={customerTypes}
                    value={selectedCustomerType}
                    name="selectedCustomerType"
                    label=""
                    onChange={handleChange}
                    className="my-2x"
                />
                {selectedCustomerType === Customer.Corporate && <CorporateForm />}
                {selectedCustomerType === Customer.Press && (
                    <CustomerForm
                        reason={Reason.Press}
                        onFormSubmit={onSubmit}
                        submitButtonState={buttonState}
                    />
                )}
                {selectedCustomerType === Customer.Provider && <ProviderForm />}
                {selectedCustomerType === Customer.Private && (
                    <>
                        <Select
                            placeholder="Bitte auswählen"
                            options={reasons}
                            value={selectedReason}
                            name="selectedReason"
                            label=""
                            onChange={handleChange}
                            className="my-2x"
                        />
                        <CustomerForm
                            reason={selectedReason}
                            onFormSubmit={onSubmit}
                            submitButtonState={buttonState}
                        />
                    </>
                )}
            </div>
        );
    };

    return renderCustomerForm();
};

// @loadable/component supports only default export for now
// eslint-disable-next-line import/no-default-export
export default ContactForm;
