import { FormattedMessage } from 'react-intl';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { TenantEnum } from '@jsmdg/tenancy';
import { Link, LinkVariant } from '@jsmdg/yoshi';
import { Reason } from '../../../../../shared/enums/contactForm';
import { type FragmentContext } from '../../../../../shared/types/fragmentContext';

type FormDescriptionProps = {
    readonly selectedReason: Reason;
};

const FormDescription = ({ selectedReason }: FormDescriptionProps): JSX.Element | null => {
    const { tenant, tenantConfig } = useFragmentContext<FragmentContext>();

    switch (selectedReason) {
        case Reason.Shipment:
            if (tenant !== TenantEnum.MD_AT && tenant !== TenantEnum.MD_CH) {
                return (
                    <div className="mb-2x">
                        <p>
                            <FormattedMessage
                                defaultMessage="Du hast deinen Gutschein in einer Geschenkverpackung bestellt? Dann schicken wir und auch DPD dir eine E-Mail mit deiner Sendungsnummer (unbedingt auch Spam Ordner prüfen). Damit kannst du direkt bei DPD den Versandstatus prüfen und auch Änderungen für die Lieferung vornehmen.
    Der Tipp hat nicht geholfen? Dann nenn uns bitte die Daten deiner Bestellung."
                            />
                        </p>
                    </div>
                );
            }

            return null;
        case Reason.Revocation:
            return (
                <div className="mb-2x">
                    <h3 className="text-uppercase">
                        <FormattedMessage defaultMessage="Widerrufsformular" />
                    </h3>
                    <p>
                        <FormattedMessage defaultMessage="Wenn du den Vertrag widerrufen willst, dann füll bitte dieses Formular aus." />
                    </p>
                </div>
            );
        case Reason.Advertising:
            return (
                <div className="mb-2x">
                    <p>
                        <FormattedMessage
                            defaultMessage="Den {brand} Newsletter kannst du direkt {link} abbestellen."
                            values={{
                                link: (
                                    <Link
                                        variant={LinkVariant.Brand}
                                        href={tenantConfig.urls.newsletterUnsubscribe}
                                    >
                                        <FormattedMessage defaultMessage="hier" />
                                    </Link>
                                ),
                                brand: tenantConfig.brandName,
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage defaultMessage="Um keine weitere Werbung per Post zu erhalten, gib bitte nachfolgend die betroffene Adresse ein." />
                    </p>
                </div>
            );
        case Reason.DateChange:
            return (
                <div>
                    <strong>
                        <FormattedMessage defaultMessage="Wie kann ich einen Termin vereinbaren?" />
                    </strong>
                    <p className="mb-1x">
                        <FormattedMessage
                            defaultMessage="Terminauskünfte und verbindliche Reservierungen kannst du direkt mit dem Erlebnis-Veranstalter absprechen. Die vollständige Anschrift und den Namen des Veranstalters erhältst du bei Einlösung des entsprechenden Erlebnisgutscheins auf {link}"
                            values={{
                                link: (
                                    <Link
                                        variant={LinkVariant.Brand}
                                        href={tenantConfig.urls.redeemVoucher}
                                    >
                                        <FormattedMessage defaultMessage="Gutschein einlösen" />
                                    </Link>
                                ),
                            }}
                        />
                    </p>

                    <strong>
                        <FormattedMessage defaultMessage="Ist sichergestellt, dass an den auf der Homepage genannten Terminen noch Plätze frei sind?" />
                    </strong>
                    <p className="mb-1x">
                        <FormattedMessage defaultMessage="Eine Garantie auf einen Platz an einem der genannten Termine kann leider nicht gewährleistet werden. Je früher du deinen Gutschein einlöst, desto höher ist die Chance, dass dein Wunschtermin noch frei ist." />
                    </p>

                    <strong>
                        <FormattedMessage defaultMessage="Muss ich mich schon beim Kauf des Gutscheins auf einen Termin festlegen?" />
                    </strong>
                    <p className="mb-1x">
                        <FormattedMessage defaultMessage="Nein, musst du nicht. Bei unseren Erlebnisgutscheinen hast du ab Kauf des Gutscheins drei Jahre lang Zeit, diesen einzulösen. Du bzw. der Beschenkte entscheidet wann und für welchen Ort der Gutschein eingelöst werden soll. Mit unseren Wertgutscheinen bist du noch flexibler und buchst erstmal kein bestimmtes Erlebnis. Dieser Gutschein kann innerhalb von drei Jahren flexibel in ein Erlebnis nach Wahl eingetauscht werden - von dir oder dem Beschenkten. Bitte beachte, dass einige Erlebnisse direkt als Ticket gebucht werden können. Bei diesen Erlebnis-Tickets stehen Termin und Veranstaltungsort bereits fest. Daher ist eine Umbuchung auf einen anderen Termin, einen anderen Veranstaltungsort oder einen anderen Teilnehmer nach Kauf nicht möglich." />
                    </p>
                    <strong>
                        <FormattedMessage defaultMessage="Kann ich meinen Termin absagen oder verschieben?" />
                    </strong>
                    <p className="mb-1x">
                        <FormattedMessage
                            defaultMessage="Bitte richte Terminverschiebungen und -absagen immer direkt an den Erlebnis-Veranstalter. Die Kontaktadresse findest du in den zugesandten Unterlagen oder nach erneuter Eingabe deines Gutscheincodes unter {link}. Bitte beachte hierbei die Stornofristen und Informationen des Erlebnis-Veranstalters."
                            values={{
                                link: (
                                    <Link
                                        variant={LinkVariant.Brand}
                                        href={tenantConfig.urls.redeemVoucher}
                                    >
                                        <FormattedMessage defaultMessage="Gutschein einlösen" />
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage defaultMessage="Bei weiteren Fragen kannst du uns auch gerne anrufen:" />
                    </p>
                    <p>
                        <FormattedMessage
                            defaultMessage="Deutschland: {link}"
                            values={{
                                link: (
                                    <Link
                                        variant={LinkVariant.Brand}
                                        href={`tel:${tenantConfig.tenantContacts.de.number}`}
                                        internal
                                    >
                                        {tenantConfig.tenantContacts.de.label}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            defaultMessage="Österreich: {link} (Es fallen die Kosten deines Providers für Telefonate in das österreichische Festnetz an)"
                            values={{
                                link: (
                                    <Link
                                        variant={LinkVariant.Brand}
                                        href={`tel:${tenantConfig.tenantContacts.at.number}`}
                                        internal
                                    >
                                        {tenantConfig.tenantContacts.at.label}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            defaultMessage="Schweiz: {link} (Es fallen die Kosten deines Providers für Telefonate in das Schweizer Festnetz an)"
                            values={{
                                link: (
                                    <Link
                                        variant={LinkVariant.Brand}
                                        href={`tel:${tenantConfig.tenantContacts.ch.number}`}
                                        internal
                                    >
                                        {tenantConfig.tenantContacts.ch.label}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                </div>
            );
        default:
            return null;
    }
};

export { FormDescription };
