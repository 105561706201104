export enum Reason {
    Experience = 'reasonExperience',
    Coupon = 'reasonCoupon',
    Shipment = 'reasonShipment',
    Revocation = 'reasonRevocation',
    Other = 'reasonOther',
    DateChange = 'reasonDateChange',
    Group = 'reasonGroup',
    Advertising = 'reasonAdvertising',
    Feedback = 'reasonFeedback',
    Press = 'press',
}

export enum Customer {
    Corporate = 'corporate',
    Private = 'private',
    Press = 'press',
    Provider = 'provider',
}

export enum FieldName {
    FirstName = 'firstName',
    LastName = 'lastName',
    Email = 'email',
    Phone = 'phone',
    Street = 'street',
    ZipCode = 'zipCode',
    Code = 'code',
    Location = 'location',
    HouseNumber = 'houseNumber',
    City = 'city',
    Experience = 'experience',
    Feedback = 'feedback',
    MyMessage = 'myMessage',
    Question = 'question',
    CodeRequired = 'codeRequired',
    Remarks = 'remarks',
    Revocation = 'revocation',
    OrderNumber = 'orderNumber',
    OrderedOn = 'orderedOn',
    ReceivedOn = 'receivedOn',
    Newspaper = 'newspaper',
    Date = 'date',
    File = 'file',

    // Group
    Link = 'link',
    Dates = 'dates',
    Time = 'time',
    Amount = 'amount',
}

export enum FormAction {
    Loading = 'loading',
    Success = 'success',
    Error = 'error',
}
