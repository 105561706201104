import { FormattedMessage } from 'react-intl';

import { Button, ButtonColor, RenderType } from '@jsmdg/yoshi';

const ProviderForm = (): JSX.Element => {
    return (
        <div>
            <h2 className="mt-2x text-uppercase">
                <FormattedMessage defaultMessage="wir sind der starke partner für deine erlebnisse!" />
            </h2>
            <ul className="mb-2x">
                <li className="mb-1x">
                    <FormattedMessage defaultMessage="Wir vermarkten deine Erlebnisse als Erlebnisgeschenk und sprechen für dich neue Zielgruppen an, welche dir zusätzliche Kunden bringen" />
                </li>
                <li className="mb-1x">
                    <FormattedMessage defaultMessage="Mit einer Partnerschaft genießt du den Vorteil ganzjähriger TV-Präsenz im Verbund mit der ProSiebenSat.1 Group" />
                </li>
                <li className="mb-1x">
                    <FormattedMessage defaultMessage="Wir platzieren deine Angebote online, im stationären Handel und in über 40 eigenen Shops im deutschsprachigen Raum" />
                </li>
            </ul>
            <p className="mb-2x">
                <FormattedMessage defaultMessage="Wir sind nicht nur Vermittler deines Erlebnisse, sondern auch starker Partner für reichweitenstarkes Marketing, bei der Buchungsabwicklung und der Kundenbetreuung." />
            </p>
            <Button
                as={RenderType.Link}
                color={ButtonColor.Complementary}
                href="https://partner.jsmd-group.com/"
                target="_blank"
            >
                <FormattedMessage defaultMessage="Partner werden" />
            </Button>
        </div>
    );
};

export { ProviderForm };
