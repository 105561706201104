import { FieldName, Reason } from '../../../../shared/enums/contactForm';
import { type BasicField } from '../../../../shared/types/contactForm';
import { messages } from './messages';

const fullName = [
    {
        name: FieldName.FirstName,
        message: messages.firstName,
        required: true,
    },
    {
        name: FieldName.LastName,
        message: messages.lastName,
        required: true,
    },
];

const email = {
    name: FieldName.Email,
    message: messages.email,
    required: true,
};

const fileUpload = {
    name: FieldName.File,
    message: messages.fileUpload,
    required: false,
    type: 'file',
};

const formSchema: Record<Reason, BasicField[]> = {
    [Reason.Experience]: [
        ...fullName,
        email,
        { name: FieldName.Code, message: messages.code, required: false },
        {
            name: FieldName.Experience,
            message: messages.experience,
            required: true,
        },
        { name: FieldName.Location, message: messages.location, required: true },
        {
            name: FieldName.Question,
            message: messages.question,
            required: true,
            textarea: true,
        },
        fileUpload,
    ],
    [Reason.Group]: [
        ...fullName,
        email,
        {
            name: FieldName.Experience,
            message: messages.experience,
            required: true,
        },
        { name: FieldName.Link, message: messages.link, required: true },
        { name: FieldName.Location, message: messages.location, required: true },
        { name: FieldName.Dates, message: messages.dates, required: true },
        { name: FieldName.Time, message: messages.time, required: true },
        { name: FieldName.Amount, message: messages.amount, required: true },
        {
            name: FieldName.MyMessage,
            message: messages.myMessage,
            required: false,
            textarea: true,
        },
        fileUpload,
    ],
    [Reason.Coupon]: [
        ...fullName,
        email,
        {
            name: FieldName.Experience,
            message: messages.experience,
            required: true,
        },
        { name: FieldName.CodeRequired, message: messages.code, required: true },
        {
            name: FieldName.Question,
            message: messages.question,
            required: true,
            textarea: true,
        },
        fileUpload,
    ],
    [Reason.Shipment]: [
        ...fullName,
        email,
        {
            name: FieldName.OrderNumber,
            message: messages.orderNumber,
            required: true,
        },
        {
            name: FieldName.Remarks,
            message: messages.remarks,
            required: false,
            textarea: true,
        },
        fileUpload,
    ],
    [Reason.Revocation]: [
        {
            name: FieldName.Revocation,
            message: messages.revocation,
            required: true,
        },
        { name: FieldName.OrderedOn, message: messages.orderedOn, required: true },
        {
            name: FieldName.ReceivedOn,
            message: messages.receivedOn,
            required: true,
        },
        ...fullName,
        { name: FieldName.Street, message: messages.street, required: true },
        {
            name: FieldName.HouseNumber,
            message: messages.houseNumber,
            required: true,
        },
        { name: FieldName.ZipCode, message: messages.zipCode, required: true },
        { name: FieldName.City, message: messages.city, required: true },
        email,
        {
            name: FieldName.OrderNumber,
            message: messages.orderNumber,
            required: true,
        },
        { name: FieldName.Date, message: messages.date, required: true },
        fileUpload,
    ],
    [Reason.Other]: [
        ...fullName,
        email,
        { name: FieldName.Code, message: messages.code, required: false },
        {
            name: FieldName.Question,
            message: messages.question,
            required: true,
            textarea: true,
        },
        fileUpload,
    ],
    [Reason.DateChange]: [],
    [Reason.Advertising]: [
        ...fullName,
        email,
        { name: FieldName.Street, message: messages.street, required: true },
        {
            name: FieldName.HouseNumber,
            message: messages.houseNumber,
            required: true,
        },
        { name: FieldName.ZipCode, message: messages.zipCode, required: true },
        { name: FieldName.City, message: messages.city, required: true },
        fileUpload,
    ],
    [Reason.Feedback]: [
        ...fullName,
        email,
        { name: FieldName.Code, message: messages.code, required: false },
        {
            name: FieldName.Experience,
            message: messages.experience,
            required: true,
        },
        { name: FieldName.Location, message: messages.location, required: true },
        {
            name: FieldName.Feedback,
            message: messages.feedback,
            required: true,
            textarea: true,
        },
        fileUpload,
    ],
    [Reason.Press]: [
        ...fullName,
        { name: FieldName.Newspaper, message: messages.newspaper, required: true },
        { name: FieldName.Phone, message: messages.phone, required: true },
        { name: FieldName.Email, message: messages.email, required: false },
        {
            name: FieldName.MyMessage,
            message: messages.myMessage,
            required: false,
            textarea: true,
        },
    ],
};
export { formSchema };
