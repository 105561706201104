import { ExclamationTriangleIcon, Wysiwyg } from '@jsmdg/yoshi';
import styles from './ErrorMessage.module.scss';

type ErrorMessageProps = {
    readonly content: string;
};

const ErrorMessage = ({ content }: ErrorMessageProps): JSX.Element => (
    <div className="grid">
        <div className="g-col-12 g-col-xs-2 d-flex justify-content-center">
            <ExclamationTriangleIcon className={styles.icon} />
        </div>
        <div className="g-col-12 g-col-xs-10 d-flex align-items-center">
            <Wysiwyg className="text-center text-sm-left" content={content} />
        </div>
    </div>
);

export { ErrorMessage };
